.radio-button {
  display: -ms-flexbox;
  display: flex; }
  .radio-button_disabled {
    opacity: 0.45; }
  .radio-button__field {
    margin-right: 10px; }
  .radio-button__label {
    display: block;
    color: #424242;
    font-size: 1.2rem;
    font-family: Roboto;
    cursor: pointer; }
    .radio-button_disabled .radio-button__label {
      cursor: default; }
  .radio-button__comment {
    margin-top: 3px;
    color: #388e3c;
    font-weight: 400;
    font-size: 0.8rem;
    font-family: Roboto; }
