.shop-details {
  text-align: left; }
  .shop-details__title {
    margin: 0;
    margin-bottom: 15px;
    color: #388e3c;
    font-weight: 500;
    font-size: 1.8rem;
    font-family: Roboto; }
  .shop-details__icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: middle; }
  .shop-details__info-item {
    margin-top: 10px; }
  .shop-details__text {
    display: inline-block;
    margin: 0;
    margin-left: 5px;
    color: #424242;
    vertical-align: middle;
    font-weight: 500;
    font-size: 1.2rem;
    font-family: Roboto; }
    .shop-details__text a:focus, .shop-details__text a:active, .shop-details__text a:visited {
      color: #424242; }
