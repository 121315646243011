.photo {
  position: relative;
  width: 100%;
  height: 100%; }
  .photo__wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: center;
        justify-content: center;
    overflow: hidden;
    height: 100%;
    background-color: #fff;
    transition: transform 0.3s ease;
    transform: scale(1, 1);
    will-change: transition; }
    .photo__wrapper_preview:hover {
      z-index: 10;
      border: 1px solid #ffc200;
      transform: scale(2, 2); }
  .photo__bg {
    height: 100%; }
  .photo_type_css {
    height: 100%; }
    .photo_type_css .photo__bg {
      height: 100%;
      background-position: center;
      background-size: cover; }
