.main-layout {
  width: 100%; }
  .main-layout__slider {
    position: relative;
    width: 100%; }
    .main-layout__slider-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 2;
      display: inline-block;
      box-sizing: border-box;
      margin: auto;
      padding: 15px;
      width: 45px;
      height: 45px;
      background-color: #008f32;
      opacity: 0.85;
      cursor: pointer; }
      .main-layout__slider-arrow-icon {
        margin: auto;
        width: 50%;
        height: 100%; }
      .main-layout__slider-arrow path {
        fill: #fff !important; }
      .main-layout__slider-arrow_type_left {
        left: 0;
        transform: scale(-1, -1); }
      .main-layout__slider-arrow_type_right {
        right: 0; }
      .main-layout__slider-arrow svg {
        width: 100%;
        height: 100%; }
    .main-layout__slider-dots {
      padding: 0;
      list-style: none;
      text-align: center; }
      .main-layout__slider-dots li {
        display: inline-block;
        margin-top: 1rem;
        margin-left: 50px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #008f32;
        cursor: pointer; }
        .main-layout__slider-dots li:first-child {
          margin-left: 0; }
        .main-layout__slider-dots li.slick-active, .main-layout__slider-dots li:hover {
          background-color: #ffc200; }
      .main-layout__slider-dots button {
        opacity: 0;
        cursor: pointer; }
  .main-layout__slide {
    position: relative; }
    .main-layout__slide-descr {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin: auto;
      width: 60%; }
      .main-layout__slide-descr-content {
        margin: 0 auto;
        padding: 30px;
        max-width: 400px;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-family: Roboto; }
    .main-layout__slide-title {
      margin: 0;
      font-weight: bold;
      font-size: 2rem; }
    .main-layout__slide-text {
      margin: 0;
      margin-top: 30px;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5; }
    .main-layout__slide-image {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      overflow: hidden;
      width: 100%;
      height: 350px; }
    .main-layout__slide-picture {
      margin: 0 auto;
      height: 100%; }
  .main-layout__news {
    margin-top: 80px; }
