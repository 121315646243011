html {
  font-size: 14px;
  font-family: Roboto, Arial, sans-serif; }
  @media only screen and (max-width: 1090px) {
    html {
      font-size: 11px; } }
  @media only screen and (min-width: 1090px) and (max-width: 1200px) {
    html {
      font-size: 12px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    html {
      font-size: 12px; } }

body {
  margin: 0;
  font-size: 1rem; }

html,
body,
#root {
  height: 100%; }

#root {
  min-width: 1000px; }
  #root svg {
    display: block; }

@font-face {
  font-weight: 900;
  font-style: italic;
  font-family: Roboto;
  src: url(/fonts/50705c5ed1205b63efdbfee941a6b655.ttf) format("truetype"); }

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: Roboto;
  src: url(/fonts/ec4c9962ba54eb91787aa93d361c10a8.ttf) format("truetype"); }

@font-face {
  font-weight: 700;
  font-style: italic;
  font-family: Roboto;
  src: url(/fonts/1eb7a893589ddce89d81cdb22a356660.ttf) format("truetype"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: Roboto;
  src: url(/fonts/ee7b96fa85d8fdb8c126409326ac2d2b.ttf) format("truetype"); }

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: Roboto;
  src: url(/fonts/bd19ad60600a1537c00d3b4923a5e5de.ttf) format("truetype"); }

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: Roboto;
  src: url(/fonts/d08840599e05db7345652d3d417574a9.ttf) format("truetype"); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: Roboto;
  src: url(/fonts/3e1af3ef546b9e6ecef9f3ba197bf7d2.ttf) format("truetype"); }
