.full-shops-view {
  text-align: center; }
  .full-shops-view__preloader {
    margin-top: 20px; }
  .full-shops-view__cities {
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc; }
  .full-shops-view__city {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 30px;
    text-align: left; }
    .full-shops-view__city:first-child {
      margin-left: 0; }
    .full-shops-view__city-title {
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #ccc;
      font-weight: normal;
      font-size: 1.6rem; }
  .full-shops-view__shop {
    display: block;
    margin-top: 10px;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    color: #388e3c;
    text-align: left;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: Roboto;
    cursor: pointer; }
    .full-shops-view__shop:hover {
      text-decoration: underline; }
  .full-shops-view__detailed {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc; }
