.sign-in {
  box-sizing: border-box;
  padding: 25px 50px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.35); }
  .sign-in__fields {
    padding-bottom: 20px;
    border-bottom: 1px solid #cdcdcd; }
  .sign-in__title {
    margin: 0;
    color: #424242;
    text-align: center;
    font-weight: 400;
    font-size: 2.6rem;
    font-family: Roboto; }
  .sign-in__field {
    margin-top: 20px; }
  .sign-in__controls {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center; }
  .sign-in__control {
    margin-top: 15px;
    width: 150px;
    text-align: center; }
  .sign-in__forgot {
    margin-top: 15px;
    outline: none;
    border: none;
    background: none;
    color: #388e3c;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto;
    cursor: pointer; }
    .sign-in__forgot:hover {
      text-decoration: underline; }
  .sign-in__errors {
    margin: 0;
    margin-top: 15px;
    color: #c51616;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto; }
  .sign-in__open {
    padding: 0;
    outline: none;
    border: none;
    background-color: none;
    cursor: pointer; }
    .sign-in__open:hover {
      text-decoration: underline; }
  .sign-in__no-acc-label {
    color: #424242;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto; }
  .sign-in__footer {
    text-align: center; }
  .sign-in__to-signup {
    padding: 0;
    outline: none;
    border: none;
    background: none;
    color: #388e3c;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: Roboto;
    cursor: pointer; }
    .sign-in__to-signup:hover {
      text-decoration: underline; }
