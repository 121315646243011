.order-basket__create-order {
  margin-top: 30px; }

.order-basket__submit {
  margin-top: 30px;
  text-align: right; }

.order-basket__order {
  text-align: center;
  font-size: 1.5rem;
  font-family: Roboto; }

.order-basket__link {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: Roboto; }
  .order-basket__link:visited {
    color: black; }
  .order-basket__link:hover {
    text-decoration: underline; }
