.product-item:hover {
  border-color: #ffc200; }

.product-item_view_card {
  width: 100%;
  border: thin solid #ccc; }

.product-item_view_row {
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  border: thin solid #ccc; }

.product-item-card__photo {
  margin: 0 auto;
  width: 100%;
  height: 150px;
  border-bottom: thin solid #ccc;
  text-align: center;
  cursor: pointer; }

.product-item-card__content {
  box-sizing: border-box;
  padding: 15px; }

.product-item-card__descr {
  margin: 0;
  margin-top: 15px;
  min-height: 50px;
  color: #424242;
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: Roboto;
  line-height: 1.3;
  cursor: pointer; }
  .product-item-card__descr:hover {
    text-decoration: underline; }

.product-item-card__price {
  margin: 15px 0;
  color: #388e3c;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  font-family: Roboto; }

.product-item-card__text, .product-item-card__exists {
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
  font-family: Roboto; }

.product-item-row {
  min-height: 170px; }
  .product-item-row__image {
    max-height: 120px;
    width: 15%;
    text-align: center;
    cursor: pointer; }
  .product-item-row__content {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 30px; }
  .product-item-row__text, .product-item-row__price {
    margin: 0; }
  .product-item-row__text {
    margin-top: 5px;
    color: #424242;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto; }
    .product-item-row__text:first-child {
      margin-top: 0; }
    .product-item-row__text_interactive {
      cursor: pointer; }
      .product-item-row__text_interactive:hover {
        text-decoration: underline; }
  .product-item-row__price {
    color: #388e3c;
    text-align: center;
    text-align: right;
    font-weight: 400;
    font-size: 2rem;
    font-family: Roboto; }
  .product-item-row__controls, .product-item-row__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between; }
  .product-item-row__data {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .product-item-row__data-info, .product-item-row__data-quantity {
      margin-right: 15px; }
