.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 15px 30px; }
  .header__logo {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    min-width: 300px;
    width: 30%; }
    .header__logo-icon {
      margin-right: 10px;
      min-width: 45px;
      width: 10%; }
  .header__titles {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-right: 20px;
    width: 65%; }
  .header__sub-title {
    margin-top: 10px;
    width: 100%; }
  .header__title {
    margin: 0;
    color: #010101;
    letter-spacing: 1.07px;
    font-weight: 700;
    font-size: 2.1rem;
    font-family: Roboto; }
  .header__block {
    -ms-flex-positive: 1;
        flex-grow: 1; }
  .header__phones {
    margin: 0;
    color: #424242;
    font-weight: 400;
    font-size: 1.7rem;
    font-family: Roboto; }
  .header__phone {
    display: inline-block;
    margin: 0; }
  .header__under-phone-content {
    margin-top: 10px; }
  .header__menu-part {
    margin-left: 3.1%;
    padding-left: 20px;
    border-left: 2px solid #404040; }
