.view-selector {
  text-align: right; }
  .view-selector_selected_grid .view-selector__grid-decor {
    background-color: #008f32; }
  .view-selector_selected_list .view-selector__list-decor {
    background-color: #008f32; }
  .view-selector__list {
    display: inline-block; }
  .view-selector__grid {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    border-left: 1px solid #cdcdcd; }
  .view-selector__grid, .view-selector__list {
    padding: 0 5px;
    width: 30px;
    height: 30px;
    outline: none;
    border-right: 1px solid #cdcdcd;
    background: none;
    vertical-align: middle;
    cursor: pointer; }
    .view-selector__grid:hover .view-selector-grid-decor,
    .view-selector__grid:hover .view-selector-list-decor, .view-selector__list:hover .view-selector-grid-decor,
    .view-selector__list:hover .view-selector-list-decor {
      background-color: #008f32; }
  .view-selector__grid-decor {
    margin: 2px;
    width: 10px;
    height: 10px;
    background-color: #cdcdcd; }
  .view-selector__list-decor {
    margin-top: 5px;
    width: 100%;
    height: 5px;
    background-color: #cdcdcd; }
    .view-selector__list-decor:first-child {
      margin-top: 2px; }
