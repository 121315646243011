.product-details__data {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 30px;
  border: 1px solid #ccc; }

.product-details__image {
  box-sizing: border-box;
  padding: 20px;
  width: 50%;
  height: 24rem; }

.product-details__info {
  box-sizing: border-box;
  padding: 1rem;
  width: 30%;
  border-left: 1px solid #ccc; }

.product-details__label {
  margin: 0;
  margin-top: 15px;
  color: #424242;
  font-weight: 400;
  font-size: 2rem;
  font-family: Roboto; }

.product-details__price {
  margin: 0;
  margin-top: 15px;
  color: #388e3c;
  font-weight: 700;
  font-size: 3rem;
  font-family: Roboto; }

.product-details__extra {
  margin-top: 15px; }

.product-details__images {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100px;
  height: 24rem; }
  .product-details__images .slick-slider {
    width: 100%; }

.product-details__add-img {
  height: 100%;
  outline: none;
  cursor: pointer; }
  .product-details__add-img-wrapper {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -ms-flex-pack: center;
        justify-content: center;
    overflow: hidden;
    margin: 5px auto;
    width: 97% !important;
    height: 100px;
    outline: none;
    border: thin solid #fff; }
    .product-details__add-img-wrapper:hover {
      border-color: #388e3c; }

.product-details__slider-arrow {
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer; }
  .product-details__slider-arrow_type_top {
    transform: rotate(180deg); }
  .product-details__slider-arrow svg {
    width: 100%;
    height: 100%; }

.product-details__properties {
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ccc;
  border-top: none; }

.product-details__column {
  box-sizing: border-box;
  width: 50%;
  border-right: 1px solid #ccc; }
  .product-details__column_hidden {
    display: none; }
  .product-details__column_fullwidth {
    width: 100%; }
  .product-details__column:last-child {
    border-right: none; }

.product-details__row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 7px 30px;
  border-bottom: 1px solid #ccc;
  color: #424242;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: Roboto; }
  .product-details__row:last-child {
    border-bottom: none; }
  .product-details__row:first-child {
    padding: 15px 30px;
    background-color: #efefef;
    color: #424242;
    font-weight: 400;
    font-size: 1.6rem;
    font-family: Roboto; }

.product-details__descr {
  margin: 0;
  line-height: 1.5; }
