.expander__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer; }

.expander__title {
  margin: 0;
  color: #424242;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: Roboto; }

.expander__arrow {
  margin-right: 10px;
  width: 10px; }

.expander__header_opened .expander__arrow {
  transform: rotate(180deg); }

.expander__content {
  padding: 10px; }
