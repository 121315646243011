.rows-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: stretch;
      align-items: stretch;
  min-height: 100%; }
  .rows-layout__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-align: stretch;
        align-items: stretch; }
  .rows-layout__header, .rows-layout__footer {
    -ms-flex: 0 0;
        flex: 0 0; }
