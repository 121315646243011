.category-details__sub-categories {
  margin: 0 -10px;
  margin-top: 20px;
  margin-bottom: 40px; }
  .category-details__sub-categories:empty {
    margin: 0; }

.category-details__sub-category {
  box-sizing: border-box;
  margin-top: 5px;
  padding-left: 10px;
  width: 25%;
  word-break: break-word; }

.category-details__title {
  margin: 0;
  font-weight: 400;
  font-size: 2.3rem;
  font-family: Roboto; }

.category-details__products {
  margin-top: 20px; }
