.base-layout {
  width: 100%; }
  .base-layout__auth-item {
    margin-top: 5px; }
    .base-layout__auth-item:first-child {
      margin-top: 0; }
  .base-layout__crumbs {
    margin-top: 2rem;
    margin-left: 2rem; }
  .base-layout__menu-item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.285rem;
    font-family: Roboto;
    cursor: pointer; }
    .base-layout__menu-item:first-child {
      margin-top: 0; }
    .base-layout__menu-item-icon {
      margin-right: 10px;
      width: 2.5rem;
      height: 2.5rem; }
      .base-layout__menu-item-icon svg {
        width: 100%;
        height: 100%; }
      .base-layout__menu-item-icon path {
        fill: #404040 !important; }
  .base-layout__nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 0;
    background-color: #00b147;
    box-shadow: 0 0 3px #000; }
    .base-layout__nav-content {
      width: 100%;
      text-align: center; }
    .base-layout__nav-link {
      position: relative;
      display: inline-block;
      padding: 10px 3%;
      min-width: 80px;
      height: 100%;
      color: #fff;
      text-decoration: none;
      font-weight: 400;
      font-size: 1.5rem;
      font-family: Roboto; }
      .base-layout__nav-link:hover {
        background-color: #ffc200;
        text-decoration: underline; }
      .base-layout__nav-link::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: auto;
        width: 2px;
        height: calc(100% - 20px);
        background-color: #fff;
        content: ''; }
      .base-layout__nav-link:first-child {
        border-left: none; }
        .base-layout__nav-link:first-child::after {
          display: none; }
  .base-layout__content {
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    padding: 2rem;
    padding-bottom: 5rem; }
  .base-layout__left {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    max-width: 350px;
    width: 30%; }
  .base-layout__center {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 3%;
    width: 67%;
    vertical-align: top; }
  .base-layout__catalog-filters {
    margin-top: 30px; }
  .base-layout__banner {
    margin-top: 30px;
    width: 100%; }
    .base-layout__banner-img {
      width: 100%; }
