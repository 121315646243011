.auth__modal {
  box-sizing: border-box;
  min-width: 300px;
  max-width: 500px;
  width: 40%; }

.auth__name {
  margin: 0; }

.auth__sign-out {
  margin-top: 5px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  cursor: pointer; }
  .auth__sign-out:hover {
    color: #ffc200; }
