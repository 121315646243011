.personal-settings {
  border: 1px solid #ccc;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .personal-settings__title {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: Roboto; }
  .personal-settings__header {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #efefef;
    color: #424242; }
  .personal-settings__content {
    position: relative;
    padding: 15px 40px; }
  .personal-settings__loader {
    position: absolute;
    right: 40px;
    bottom: 15px; }
  .personal-settings__field {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-top: 15px; }
    .personal-settings__field-error {
      position: absolute;
      bottom: -1.2rem;
      left: 0;
      margin: 0;
      color: #c51616;
      font-size: 0.85rem; }
    .personal-settings__field:first-child {
      margin-top: 0; }
  .personal-settings__label {
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -ms-flex-preferred-size: 100px;
        flex-basis: 100px;
    color: #424242;
    text-align: right;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto; }
  .personal-settings__value {
    position: relative;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    margin-left: 20px;
    color: #424242;
    text-align: left;
    font-weight: bold;
    font-size: 1.3rem;
    font-family: Roboto; }
  .personal-settings__actions {
    -ms-flex-preferred-size: 35%;
        flex-basis: 35%;
    margin-left: 20px; }
    .personal-settings__actions .personal-settings__action:first-child {
      margin-left: 0; }
  .personal-settings__action {
    -ms-flex-preferred-size: 35%;
        flex-basis: 35%;
    margin-left: 20px;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    color: #388e3c;
    text-align: left;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto;
    cursor: pointer; }
    .personal-settings__action:hover {
      text-decoration: underline; }
  .personal-settings__column {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .personal-settings__column:last-child {
      -ms-flex-positive: 1;
          flex-grow: 1; }
