.ReactModal__Overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

body.ReactModal__Body--open {
  overflow: hidden;
  width: 100%; }
  body.ReactModal__Body--open #content {
    overflow: hidden; }

.ReactModal__Content {
  opacity: 0;
  transition: opacity 0.2s ease-in; }
  .ReactModal__Content--after-open {
    opacity: 1; }
  .ReactModal__Content--before-close {
    opacity: 0; }
