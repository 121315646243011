.title {
  display: block;
  margin: 0;
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #bebebe;
  font-weight: 400;
  font-size: 2.3rem;
  font-family: Roboto; }
  .title_theme_regular {
    padding-bottom: 10px;
    font-size: 1.7rem; }
