.paginator {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  .paginator__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin: 0;
    padding: 0;
    border-right: 1px solid #ccc;
    list-style: none; }
  .paginator__wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .paginator__page-link {
    display: block;
    box-sizing: border-box;
    padding: 10px 15px;
    outline: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    font-family: Roboto;
    cursor: pointer; }
  .paginator__page:last-child {
    border-right: 1px solid #ccc; }
  .paginator__page_active .paginator__page-link,
  .paginator__page:hover .paginator__page-link {
    background-color: #008f32;
    color: #fff; }
  .paginator__prev, .paginator__next {
    display: none; }
  .paginator__next-btn {
    margin-left: 20px; }
  .paginator__prev-btn {
    margin-right: 20px; }
    .paginator__prev-btn svg {
      transform: rotate(-180deg); }
  .paginator__next-btn-icon, .paginator__prev-btn-icon {
    width: 1rem;
    height: 1rem; }
    .paginator__next-btn-icon svg, .paginator__prev-btn-icon svg {
      width: 100%;
      height: 100%; }
    .paginator__next-btn-icon svg path, .paginator__prev-btn-icon svg path {
      fill: #fff !important; }
  .paginator__sizes {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    color: #424242;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto; }
    .paginator__sizes-label {
      margin: 0;
      margin-right: 5px; }
  .paginator__size {
    padding: 10px;
    cursor: pointer; }
    .paginator__size:hover, .paginator__size_active {
      background-color: #008f32;
      color: #fff; }
