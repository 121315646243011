.input {
  position: relative;
  width: 100%; }
  .input__field {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    outline: none;
    border: thin solid #bdbdbd;
    color: #424242;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto; }
    .input__field:hover, .input__field:focus {
      border-color: #ffc200; }
    .input__field_iconified {
      padding-left: 30px; }
  .input__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    width: 1.5rem;
    height: 1.5rem; }
