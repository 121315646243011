.button {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  outline: none;
  border: thin solid #bdbdbd;
  background-color: #fff;
  font-size: 1.2rem;
  cursor: pointer; }
  .button_inline {
    display: inline-block;
    width: auto; }
  .button_theme_green {
    background-color: #009846;
    color: #fff; }
    .button_theme_green:hover {
      background-color: #ffc200;
      color: #fff; }
  .button_theme_light:hover {
    border-color: #ffc200;
    color: #ffc200; }
    .button_theme_light:hover path {
      fill: #ffc200 !important; }
  .button_theme_light:active {
    background-color: #ffc200;
    color: #fff; }
    .button_theme_light:active path {
      fill: #fff !important; }
  .button_theme_light.button_disabled {
    color: #fff; }
    .button_theme_light.button_disabled:hover {
      color: #fff; }
  .button_disabled {
    background-color: #bdbdbd; }
    .button_disabled:hover, .button_disabled:active {
      border-color: #bdbdbd;
      background-color: #bdbdbd; }
