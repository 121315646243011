.basket-items {
  border: 1px solid #ccc;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .basket-items, .basket-items__items {
    width: 100%; }
  .basket-items__items {
    border-collapse: collapse; }
  .basket-items__header-cell {
    padding: 15px 5px;
    border: 1px solid #ccc;
    background-color: #efefef;
    color: #424242;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto; }
    .basket-items__header-cell_type_count {
      width: 10%; }
  .basket-items__items_cropped .basket-items__header-cell:first-child {
    border-left: none; }
  .basket-items__items_cropped .basket-items__header-cell:last-child {
    border-right: none; }
  .basket-items__summary-title-cell, .basket-items__summary-price-cell {
    margin: 0;
    padding: 15px 0;
    font-weight: 700;
    font-size: 1.3rem;
    font-family: Roboto; }
  .basket-items__summary-title-cell {
    color: #388e3c;
    text-align: right; }
  .basket-items__summary-price-cell {
    color: #424242;
    text-align: center; }
  .basket-items__cell {
    color: #424242;
    font-weight: 400;
    font-size: 1.1rem;
    font-family: Roboto; }
    .basket-items__cell_type_image {
      max-width: 60px; }
    .basket-items__cell_type_preloader {
      text-align: center; }
  .basket-items__photo {
    margin: 0 auto;
    width: 60px;
    height: 60px; }
  .basket-items__delete {
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer; }
    .basket-items__delete:hover svg path {
      fill: #ffc200 !important; }
    .basket-items__delete-icon {
      display: inline-block;
      padding: 0;
      width: 1rem;
      height: 1rem;
      outline: none;
      border: none;
      background: none;
      cursor: pointer; }
      .basket-items__delete-icon:hover svg path {
        fill: #ffc200 !important; }
  .basket-items__preloader {
    display: inline-block; }
  .basket-items__delete-modal {
    box-sizing: border-box;
    padding: 25px 50px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.35); }
    .basket-items__delete-modal-text {
      margin: 35px 0;
      font-size: 1.3rem; }
    .basket-items__delete-modal-controls {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .basket-items__delete-modal-controls .button {
        width: 49%; }
