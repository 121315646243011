.news-details__content-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc; }
  .news-details__content-wrapper::after {
    display: table;
    clear: both;
    content: ""; }

.news-details__title-photo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  float: left;
  overflow: hidden;
  margin-top: 15px;
  margin-right: 20px;
  width: 25%; }
  .news-details__title-photo img {
    width: 100%; }

.news-details__content {
  padding-top: 15px;
  font-size: 1.3rem;
  font-family: Roboto; }
  .news-details__content p {
    margin: 0;
    line-height: 1.5; }

.news-details__date {
  margin-top: 20px;
  color: #009846;
  text-align: right;
  font-size: 1.214rem;
  font-family: Roboto; }
