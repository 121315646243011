.orders-history {
  border-bottom: 1px solid #ccc; }
  .orders-history__row {
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    cursor: pointer; }
    .orders-history__row:hover {
      background-color: rgba(0, 0, 0, 0.05); }
  .orders-history__items {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc; }
  .orders-history__cell {
    padding: 10px 0;
    width: 30%;
    text-align: center;
    font-size: 1.2rem;
    font-family: Roboto; }
    .orders-history__cell:nth-child(1) {
      width: 10%; }
    .orders-history__cell_type_head {
      border-right: 1px solid #ccc;
      background-color: #efefef; }
      .orders-history__cell_type_head:last-child {
        border-right: none; }
  .orders-history__empty {
    margin: 0;
    padding: 20px 0;
    border: 1px solid #ccc;
    border-bottom: none;
    text-align: center;
    font-size: 1.3rem;
    font-family: Roboto; }
