.products-filters {
  padding: 20px;
  border: 1px solid #ccc; }
  .products-filters__title {
    margin: 0;
    color: #388e3c;
    font-weight: 400;
    font-size: 2rem;
    font-family: Roboto; }
  .products-filters__filter {
    margin-top: 15px; }
  .products-filters__submit {
    margin-top: 20px; }

.product-filter__inputs {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px; }

.product-filter__input {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 35%;
  font-size: 1.2rem; }
  .product-filter__input:last-child {
    margin-left: 20px; }

.product-filter__label {
  margin-right: 5px; }
