.create-order__field {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 20px; }
  .create-order__field:first-child {
    margin-top: 0; }
  .create-order__field-label {
    width: 20%;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto; }

.create-order__first-row {
  display: -ms-flexbox;
  display: flex; }

.create-order__section {
  -ms-flex-positive: 1;
      flex-grow: 1;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .create-order__section_leftmargin {
    margin-left: 25px; }
    .create-order__section_leftmargin:first-child {
      margin-left: 0; }
  .create-order__section_topmargin {
    margin-top: 25px; }
  .create-order__section-header {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #efefef;
    color: #424242; }
  .create-order__section-title {
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: Roboto; }
  .create-order__section-content {
    padding: 20px; }

.create-order__radio-field {
  margin-top: 15px; }
  .create-order__radio-field:first-child {
    margin-top: 0; }

.create-order__delivery-points {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -20px; }

.create-order__delivery-point {
  margin-left: 20px;
  width: 300pt; }

.create-order__comment {
  box-sizing: border-box;
  padding: 20px;
  min-height: 100px;
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  resize: none; }
  .create-order__comment:hover, .create-order__comment:focus {
    border-color: #ffc107; }

.create-order__submit {
  margin-top: 30px;
  text-align: right; }

.create-order__error {
  position: absolute;
  top: calc(100% + 5px);
  left: 17%;
  margin: 0;
  color: #ef5350;
  font-size: 1rem;
  font-family: Roboto; }
  .create-order__error_type_summary {
    position: static;
    text-align: right; }
