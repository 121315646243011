.change-password {
  position: relative;
  box-sizing: border-box;
  padding: 25px 50px;
  width: 450px;
  border: 1px solid #ccc;
  background-color: #fff; }
  .change-password__title {
    margin: 0;
    color: #424242;
    text-align: center;
    font-weight: 400;
    font-size: 2.6rem;
    font-family: Roboto; }
  .change-password__field, .change-password__submit {
    margin-top: 15px; }
  .change-password__error {
    margin: 10px 0 10px 0;
    color: #c51616;
    text-align: center;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto; }
    .change-password__error:empty {
      margin: 0; }
