.news-list_view_short-grid {
  padding: 3%;
  background-color: #f5f5f5; }
  .news-list_view_short-grid .news-list__row {
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px; }
    .news-list_view_short-grid .news-list__row:first-child {
      margin-top: 0; }
  .news-list_view_short-grid .news-list__item {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 3%;
    padding: 15px;
    width: 17%;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer; }
    .news-list_view_short-grid .news-list__item:hover {
      border-color: #ffc200; }
    .news-list_view_short-grid .news-list__item-img {
      overflow: hidden; }
      .news-list_view_short-grid .news-list__item-img img {
        width: 100%; }
    .news-list_view_short-grid .news-list__item-date {
      margin-top: 25px;
      margin-bottom: 0;
      color: #009846;
      font-size: 1.214rem;
      font-family: Roboto; }
    .news-list_view_short-grid .news-list__item-descr {
      margin: 0;
      margin-top: 10px;
      color: #000;
      font-size: 1.2rem;
      font-family: Roboto;
      line-height: 1.5; }
    .news-list_view_short-grid .news-list__item:first-child {
      margin-left: 0; }

.news-list_view_list .news-list__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding: 15px 0;
  height: 200px;
  border-bottom: 1px solid #ccc; }
  .news-list_view_list .news-list__item-title {
    margin: 0;
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: Roboto;
    cursor: pointer; }
    .news-list_view_list .news-list__item-title:hover {
      color: #009846;
      text-decoration: underline; }
  .news-list_view_list .news-list__item-img {
    width: 25%;
    height: 100%; }
  .news-list_view_list .news-list__item-content {
    overflow: hidden;
    margin-left: 15px;
    width: 73%;
    height: 100%; }
  .news-list_view_list .news-list__item-date {
    color: #009846;
    font-size: 1.214rem;
    font-family: Roboto; }
  .news-list_view_list .news-list__item-descr {
    margin: 0;
    margin-top: 10px;
    color: #000;
    font-size: 1.2rem;
    font-family: Roboto;
    line-height: 1.5; }

.news-list__empty {
  text-align: center;
  font-size: 1.5rem;
  font-family: Roboto; }
