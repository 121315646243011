.search-page__tabs {
  position: relative;
  margin-top: 20px; }

.search-page__view-selector {
  position: absolute;
  top: 15px;
  right: 0;
  display: inline-block; }

.search-page__results {
  margin-top: 20px; }
