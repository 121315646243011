.products-list {
  margin: 0 -10px; }
  .products-list__list, .products-list__grid {
    padding: 1px 0;
    min-height: 120px; }
  .products-list__item {
    margin: 20px 10px; }
  .products-list__paginator {
    box-sizing: border-box;
    margin-top: 20px;
    padding: 0 10px; }
  .products-list__params {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px; }
  .products-list__ordering {
    display: -ms-flexbox;
    display: flex;
    font-size: 1.3rem;
    font-family: Roboto; }
    .products-list__ordering-name {
      margin-bottom: 2px; }
    .products-list__ordering-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-left: 5px;
      padding: 0 2px;
      cursor: pointer; }
      .products-list__ordering-item_selected, .products-list__ordering-item:hover {
        color: #388e3c;
        text-decoration: underline; }
    .products-list__ordering-icon {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer; }
      .products-list__ordering-icon svg {
        width: 100%;
        height: 100%; }
      .products-list__ordering-icon_selected path, .products-list__ordering-icon:hover path {
        fill: #388e3c !important; }
      .products-list__ordering-icon_dir_up {
        transform: rotate(-90deg); }
      .products-list__ordering-icon_dir_down {
        margin-right: 5px;
        transform: rotate(90deg); }
  .products-list__empty {
    margin: 50px 0;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-family: Roboto; }
