.footer {
  box-sizing: border-box;
  padding: 40px 120px;
  background-color: #404040;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35); }
  .footer__content {
    display: -ms-flexbox;
    display: flex; }
  .footer__contact, .footer__link {
    display: block;
    margin: 0;
    margin-top: 15px; }
    .footer__contact,
    .footer__contact a, .footer__link,
    .footer__link a {
      color: #fff;
      font-weight: 400;
      font-size: 1.142rem;
      font-family: Roboto; }
    .footer__contact:first-child, .footer__link:first-child {
      margin-top: 0; }
  .footer__link:hover {
    color: #ffc200; }
  .footer__section {
    margin-left: 5.7%; }
    .footer__section:first-child {
      margin: 0; }
  .footer__title {
    margin: 0;
    margin-bottom: 40px;
    color: #fff;
    font-weight: 500;
    font-size: 2.57rem;
    font-family: Roboto; }
  .footer__copyright {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid #fff;
    color: #fff;
    font-weight: 400;
    font-size: 1.28rem;
    font-family: Roboto; }
  .footer__socials {
    margin-top: 15px; }
  .footer__social {
    display: inline-block;
    margin-left: 15px;
    width: 3rem;
    height: 3rem; }
    .footer__social:first-child {
      margin-left: 0; }
    .footer__social-icon,
    .footer__social svg {
      width: 100%;
      height: 100%; }
