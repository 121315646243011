.counter-input {
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: thin solid #bdbdbd; }
  .counter-input, .counter-input__field {
    height: 100%; }
  .counter-input__field {
    display: block;
    box-sizing: border-box;
    padding: 0;
    padding: 5px 0;
    width: 60%;
    outline: none;
    border: none;
    color: #909090;
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: Roboto; }
  .counter-input__controls {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    width: 40%; }
  .counter-input__control {
    display: block;
    margin: 0;
    padding: 0;
    height: 50%;
    outline: none;
    border: none;
    background: none;
    cursor: pointer; }
    .counter-input__control svg {
      margin: 0 auto;
      width: 7px;
      height: 7px; }
    .counter-input__control svg path {
      fill: #909090 !important; }
    .counter-input__control:hover {
      opacity: 0.8; }
    .counter-input__control:active {
      background-color: #ffc107; }
      .counter-input__control:active svg path {
        fill: #fff !important; }
    .counter-input__control_type_down {
      border-top: thin solid #bdbdbd;
      border-left: thin solid #bdbdbd; }
      .counter-input__control_type_down svg {
        transform: rotate(90deg); }
    .counter-input__control_type_up {
      border-left: thin solid #bdbdbd; }
      .counter-input__control_type_up svg {
        transform: rotate(-90deg); }
