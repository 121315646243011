.checkbox {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center; }
  .checkbox__label {
    margin-left: 10px;
    font-size: 1.2rem;
    font-family: Roboto;
    cursor: pointer; }
  .checkbox__rectangle {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid #ccc;
    cursor: pointer; }
    .checkbox__rectangle:hover {
      border-color: #ffc107; }
  .checkbox__mark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    width: 1.3rem;
    height: 1.3rem;
    content: ''; }
    .checkbox__mark svg {
      width: 100%;
      height: 100%; }
    .checkbox__mark path {
      fill: #388e3c !important; }
