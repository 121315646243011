.categories-list {
  display: block;
  box-sizing: border-box;
  width: 100%; }
  .categories-list_expandable .categories-list__title {
    cursor: pointer; }
  .categories-list_view_recursive {
    border: 2px solid #ccc; }
    .categories-list_view_recursive .categories-list__preloader {
      margin-right: 20px;
      margin-bottom: 30px;
      margin-left: 20px; }
      .categories-list_view_recursive .categories-list__preloader:empty {
        margin: 0; }
  .categories-list__list {
    background-color: #fff; }
    .categories-list__list_nested {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 20px;
      border: thin solid #ffc200;
      line-height: 1.4; }
  .categories-list__arrow {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-left: 10px;
    width: 20px;
    height: 20px; }
    .categories-list__arrow path {
      fill: #008f32 !important; }
    .categories-list__arrow svg {
      width: 100%;
      height: 100%; }
  .categories-list__category {
    position: relative;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: Roboto; }
    .categories-list__category-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: justify;
          justify-content: space-between;
      padding: 10px 20px;
      border-bottom: thin solid #ccc;
      cursor: pointer; }
      .categories-list__category-item:hover {
        color: #ffc200; }
    .categories-list__category_nested .categories-list__category-item {
      padding: 5px;
      border: none; }
      .categories-list__category_nested .categories-list__category-item:hover {
        text-decoration: underline; }
    .categories-list__category:first-child {
      padding-top: 0;
      border-top: 1px solid #bebebe; }
    .categories-list__category:last-child .categories-list__category-item {
      border-bottom: none; }
    .categories-list__category:hover > .categories-list__category-children {
      display: block; }
    .categories-list__category:hover > .categories-list__category-item {
      border-color: #ffc200;
      color: #ffc200; }
    .categories-list__category:hover > .categories-list__category-item > .categories-list__arrow path {
      fill: #ffc200 !important; }
    .categories-list__category_nested {
      width: 200px; }
      .categories-list__category_nested:first-child {
        border-top: none; }
    .categories-list__category-children {
      position: absolute;
      top: -1px;
      left: 100%;
      z-index: 5;
      display: none;
      box-sizing: border-box;
      min-width: 500px;
      width: 65vw; }
  .categories-list__title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-left: 40px;
    color: #388e3c;
    font-weight: 400;
    font-size: 2rem;
    font-family: Roboto; }
  .categories-list__expand {
    width: 1.3rem;
    height: 1.3rem;
    transition: transform 0.3s ease;
    transform: rotate(0); }
    .categories-list__expand svg {
      width: 100%;
      height: 100%; }
      .categories-list__expand svg path {
        fill: #388e3c !important; }
    .categories-list_expanded .categories-list__expand {
      transform: rotate(90deg); }
  .categories-list__cat-item-content {
    box-sizing: border-box;
    margin: 10px;
    padding: 20px;
    border: thin solid #ccc;
    background-color: #fff;
    cursor: pointer; }
    .categories-list__cat-item-content:hover {
      border-color: #ffc200; }
  .categories-list__cat-item-photo {
    margin: 0 auto;
    width: 150px;
    height: 150px; }
  .categories-list__cat-item-child {
    margin: 5px 0;
    font-size: 1.2rem;
    line-height: 1.5;
    cursor: pointer; }
    .categories-list__cat-item-child:hover {
      text-decoration: underline; }
  .categories-list__cat-item-title {
    margin: 0;
    margin-top: 10px;
    color: #009846;
    word-break: break-word;
    font-size: 1.5rem;
    font-family: Roboto;
    line-height: 1.3;
    cursor: pointer; }
    .categories-list__cat-item-title:hover {
      text-decoration: underline; }
  .categories-list__empty {
    margin: 50px 20px;
    text-align: center;
    font-size: 1.5rem;
    font-family: Roboto; }
