.tabs__tab-head {
  padding: 15px 15px;
  outline: none;
  border: none;
  border-left: 1px solid #cdcdcd;
  background: none;
  color: #424242;
  cursor: pointer; }
  .tabs__tab-head:last-child {
    border-right: 1px solid #cdcdcd; }
  .tabs__tab-head:hover, .tabs__tab-head_active {
    color: #388e3c; }

.tabs__tab-title {
  margin: 0;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: Roboto; }
