.breadcrumbs__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }

.breadcrumbs__link {
  color: #bdbdbd;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: Roboto;
  line-height: 30px; }
  .breadcrumbs__link:visited {
    color: #bdbdbd; }
  .breadcrumbs__link:hover, .breadcrumbs__link_active {
    color: #388e3c;
    text-decoration: underline; }
  .breadcrumbs__link_active:visited {
    color: #388e3c; }

.breadcrumbs__crumb::after {
  display: inline-block;
  margin: 5px;
  color: #bdbdbd;
  content: '/';
  font-size: 1.3rem; }

.breadcrumbs__crumb:last-child::after {
  display: none; }
