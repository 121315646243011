.add-to-basket {
  display: -ms-flexbox;
  display: flex; }
  .add-to-basket__count {
    -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    height: 100%; }
  .add-to-basket__submit {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: 70%;
        flex-basis: 70%; }
  .add-to-basket__btn-label {
    font-size: 1.2rem; }
    @media only screen and (max-width: 1090px) {
      .add-to-basket__btn-label {
        font-size: 0.86rem; } }
    @media only screen and (min-width: 1090px) and (max-width: 1200px) {
      .add-to-basket__btn-label {
        font-size: 0.86rem; } }
  .add-to-basket__preloader {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translate(-50%, -50%); }
