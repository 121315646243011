.auth-restore {
  position: relative;
  box-sizing: border-box;
  padding: 25px 50px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.35); }
  .auth-restore__title {
    margin: 0;
    color: #424242;
    text-align: center;
    font-weight: 400;
    font-size: 2.6rem;
    font-family: Roboto; }
  .auth-restore__preloader {
    position: absolute;
    right: 10px;
    bottom: 25px;
    display: inline-block; }
  .auth-restore__field {
    margin-top: 20px; }
  .auth-restore__back {
    display: block;
    margin-top: 15px;
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    background: none;
    color: #388e3c;
    text-align: center;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto;
    cursor: pointer; }
    .auth-restore__back:hover {
      text-decoration: underline; }
  .auth-restore__submit {
    margin-top: 15px; }
  .auth-restore__success, .auth-restore__hint {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    font-family: Roboto;
    line-height: 1.3; }
  .auth-restore__success {
    margin-top: 15px;
    text-align: center;
    font-size: 1.3rem; }
  .auth-restore__error {
    margin-top: 10px;
    color: #c51616;
    text-align: center;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto; }
    .auth-restore__error:empty {
      margin: 0; }
