.sign-up {
  box-sizing: border-box;
  padding: 25px 50px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.35); }
  .sign-up__title {
    margin: 0;
    color: #424242;
    text-align: center;
    font-weight: 400;
    font-size: 2.6rem;
    font-family: Roboto; }
  .sign-up__field {
    margin-top: 20px; }
  .sign-up__submit {
    margin: 20px auto;
    width: 150px; }
  .sign-up__footer {
    margin-top: 30px;
    border-top: thin solid #bdbdbd; }
  .sign-up__to-signin {
    display: block;
    margin-top: 20px;
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    background: none;
    color: #388e3c;
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: Roboto;
    cursor: pointer; }
    .sign-up__to-signin:hover {
      text-decoration: underline; }
  .sign-up__error {
    margin: 10px 0 10px 0;
    color: #c51616;
    text-align: center;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: Roboto; }
    .sign-up__error:empty {
      margin: 0; }
  .sign-up__confirmation {
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: Roboto;
    line-height: 1.3; }
